import { useTrackTitanStore } from 'src/zustand/store';
import { v4 as uuidv4 } from 'uuid';
import { useEffect } from 'react';
import { useTrackTitanHydratedStore } from 'src/zustand/hydrated';
import Cookies from 'js-cookie';

export const useRegisterId = () => {
    const { user } = useTrackTitanHydratedStore((state) => state.auth);
    const { registerId } = useTrackTitanHydratedStore((state) => state.storage);
    const { setRegisterId } = useTrackTitanStore((state) => state.storage);
    const cookieRegisterId = Cookies.get('tt-register-id');

    const setRegisterIdInStorage = async () => {
        const generatedRegisterId = uuidv4();

        setRegisterId(generatedRegisterId);
        Cookies.set('tt-register-id', generatedRegisterId, { domain: '.tracktitan.io', expires: 1 });
    };

    useEffect(() => {
        if (user) return;
        if (!cookieRegisterId && !registerId) {
            setRegisterIdInStorage();
            return;
        }
        if (!cookieRegisterId) {
            Cookies.set('tt-register-id', registerId, { domain: '.tracktitan.io', expires: 1 });
            return;
        }
        if (!registerId) {
            setRegisterId(cookieRegisterId);
        }
    }, [registerId, cookieRegisterId, user]);
};
