import { SessionsResponse, getSessions } from './getSessions';
import { useQuery } from 'react-query';
import { VulcanResponse } from '../types';

export const useGetSessions = (
    userId?: string,
    limit = 10,
    sessionsData?: VulcanResponse<SessionsResponse>,
    page: number = 1
) => {
    const query = useQuery(['getSesssions', { userId, page, limit }], () => getSessions(userId, limit, page), {
        keepPreviousData: true
    });

    if (!query.data && sessionsData) {
        return { ...query, data: sessionsData, isLoading: false, isError: false };
    }

    return query;
};
