import { useMemo } from 'react';
import { gameIncludesCars } from 'src/utilities/gameIncludesCars';
import { useGetLastSession } from 'src/queries/session/useGetLastSession';
import { useTrackTitanHydratedStore } from 'src/zustand/hydrated';

// Maybe think about grabbing this from sanity?
export const defaultLeaderboardLink = '/leaderboards/assettoCorsaCompetizione/brands_hatch/lamborghini_huracan_gt3_evo';

export const useLeaderboardLink = () => {
    const { user } = useTrackTitanHydratedStore((state) => state.auth);
    const { data: lastSessionData, isLoading } = useGetLastSession(user?.uuid);

    const leaderboardLink = useMemo(() => {
        if (lastSessionData?.data) {
            const { game, track, car } = lastSessionData?.data;
            return `/leaderboards/${game?.gameId}/${track?.trackId}${
                gameIncludesCars(game?.gameId) ? `/${car?.carId}` : ''
            }`;
        }

        return defaultLeaderboardLink;
    }, [lastSessionData]);

    return { isLoading, leaderboardLink };
};
