import { useEffect, useState } from 'react';
import { Switch } from '@headlessui/react';
import { usePathname, useRouter } from 'next/navigation';
import Image from 'next/image';
import Link from 'next/link';
import Tooltip from 'react-tooltip';
import { useMediaQuery } from 'react-responsive';

import { isCurrentRouteInList } from 'src/utilities';
import { BurgerMenu } from 'components/BurgerMenu';
import { testModeClickedEvent } from 'src/analytics/layout/testModeClicked';
import { mediaQuery } from 'src/tokens';
import { useTrackTitanStore } from 'src/zustand/store';
import { useRecentActivity } from 'src/hooks/useRecentActivity';
import ProfileHeaderCard from 'components/ProfileHeaderCard';
import { useSignOut } from 'src/hooks/useSignOut';
import { useGetLapsDrivenCount } from 'src/queries/stats/getLapDrivenCount';
import { useGetSupportedGames } from 'src/queries/sanity/useGetSupportedGames';
import { useTrackTitanHydratedStore } from 'src/zustand/hydrated';
import HeaderUpgrade from 'components/HeaderUpgrade';
import LapsDriven from 'components/LapsDriven';
import { useGetLapUsage } from 'src/queries/user/useGetLapUsage';
import { useShowSimplifiedLayout } from 'src/hooks/useShowSimplifiedLayout';

interface IHeader {
    rightSidebarWidth: number;
    openOnboarding: () => void;
}

export const Header = ({ rightSidebarWidth, openOnboarding }: IHeader) => {
    const { user, isTestMode } = useTrackTitanHydratedStore((state) => state.auth);
    const { setIsTestMode } = useTrackTitanStore((state) => state.auth);
    const router = useRouter();
    const pathname = usePathname();
    const [analysisRoute, setAnalysisRoute] = useState<string | undefined>();
    const isTabletOrLarger = useMediaQuery({ query: mediaQuery.tablet });
    const { testUserLastSessionId } = useTrackTitanHydratedStore((state) => state.app);

    const isAnalysisRoute = isCurrentRouteInList(['/session', '/segment', '/sessions/'], pathname);
    const simplifiedLayout = useShowSimplifiedLayout();

    const { hasRecentActivity } = useRecentActivity(8, 'days');

    const { data: lapsDrivenData } = useGetLapsDrivenCount();
    const { data: supportedGamesData } = useGetSupportedGames();
    const { data: lapUsageData } = useGetLapUsage(user?.uuid);
    useEffect(() => {
        // if no longer in an analysis route then clear the saved analysis route
        if (!isAnalysisRoute) {
            setAnalysisRoute(undefined);
        }
    }, [isAnalysisRoute]);

    const shouldShowTestModeToggle =
        isCurrentRouteInList(['/dashboard', '/sessions', '/session', '/segment'], pathname) && !user;

    const handleTestToggleChange = () => {
        testModeClickedEvent(pathname, user?.email, user?.uuid);

        if (isTestMode) {
            const newRoute = analysisRoute === undefined ? '/dashboard' : analysisRoute;

            setIsTestMode(false);
            return router.push(newRoute);
        }

        const isAnalysisRoute = isCurrentRouteInList(['/session', '/segment', '/sessions/'], pathname);

        if (testUserLastSessionId && isAnalysisRoute && pathname) {
            // saving current route before changing route
            setAnalysisRoute(pathname);

            const route =
                /\/sessions\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/\d{14}\/\d+\/\d+\?/g.test(
                    pathname
                )
                    ? `/sessions/${process.env.NEXT_PUBLIC_DUMMY_ACCOUNT_UUID}/${testUserLastSessionId}/1/1`
                    : `/sessions/${process.env.NEXT_PUBLIC_DUMMY_ACCOUNT_UUID}/${testUserLastSessionId}`;

            router.push(route);
        }
        setIsTestMode(true);
    };

    const handleSignOut = useSignOut();

    if (process.env.NEXT_PUBLIC_MOBILE_APP === 'true') {
        return null;
    }

    const hideUpgradeButton =
        (user?.isTrial && user.dateTimeTrialEnds) ||
        user?.isPremiumPlan ||
        user?.isUltraPlan ||
        (user?.isPlusPlan && !user.isTrial);

    return (
        <div
            className="px-4 md:px-6 bg-newHeaderBackground left-0 h-14 md:h-18 fixed flex top-0 justify-between shadow-normal transition-all duration-500"
            style={{ zIndex: 1000, right: `${isTabletOrLarger ? rightSidebarWidth : 0}rem` }}
        >
            <Link
                href="/"
                className="flex cursor-pointer hover:bg-grey-15 transition-all duration-500 -mx-6 h-14 md:h-18 items-center justify-center"
                style={{ width: '5.5rem' }}
            >
                <Image src="/logo_negative.svg" alt="logo" width="48" height="48" style={{ height: 'auto' }} />
            </Link>

            {lapsDrivenData && supportedGamesData && (
                <div className="flex md:justify-end md:flex-1 ml-4 md:ml-0 md:mr-8">
                    <LapsDriven
                        lapCountResponse={lapsDrivenData}
                        games={supportedGamesData}
                        isTabletOrLarger={isTabletOrLarger}
                        isUnauth={!user}
                    />
                </div>
            )}

            {user && !hideUpgradeButton && (
                <div className="hidden lg:flex  items-center">
                    <HeaderUpgrade user={user} lapUsageData={lapUsageData} />
                </div>
            )}

            {user && (
                <div className="flex gap-2 md:gap-5">
                    {!hasRecentActivity && (
                        <div className="hidden lg:flex items-center justify-center">
                            <Switch.Group>
                                <div
                                    className={`${shouldShowTestModeToggle ? 'flex' : 'hidden'} items-center`}
                                    data-tip="Use the test mode to explore the platform with dummy data"
                                    data-for="testModeToggle"
                                >
                                    <Switch.Label className={`mr-4 ${isTestMode ? 'text-testMode' : ''}`}>
                                        Test Mode
                                    </Switch.Label>
                                    <Switch
                                        checked={isTestMode}
                                        onChange={handleTestToggleChange}
                                        className={`${
                                            isTestMode ? 'bg-testMode' : 'bg-gray-200'
                                        } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                                    >
                                        <span
                                            className={`${
                                                isTestMode ? 'translate-x-6' : 'translate-x-1'
                                            } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                                        />
                                    </Switch>
                                </div>
                                <Tooltip multiline className="text-center" id="testModeToggle" />
                            </Switch.Group>
                        </div>
                    )}
                    <div className="-mr-2 flex">
                        <ProfileHeaderCard
                            userId={user.uuid}
                            email={user.email}
                            username={user.displayName ?? ''}
                            isPlusPlan={!!user.isPlusPlan}
                            isPremiumPlan={!!user.isPremiumPlan}
                            isUltraPlan={!!user.isUltraPlan}
                            isSetupSubscriptionPlan={!!user.isSetupSubscriptionPlan}
                            profilePicTimestamp={user.profilePicTimestamp ?? 0}
                            lapUsageData={lapUsageData}
                        />
                    </div>
                </div>
            )}

            <div className={simplifiedLayout ? 'h-14 md:h-18 flex items-center' : 'lg:hidden h-full'}>
                {/* mobile navigation menu */}
                <div className="pb-7 ml-4 h-full flex items-center">
                    <BurgerMenu user={user} authSignOut={handleSignOut} openOnboarding={openOnboarding} />
                </div>
            </div>
        </div>
    );
};
