import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { useTrackTitanHydratedStore } from 'src/zustand/hydrated';
import { useTrackTitanStore } from 'src/zustand/store';

const urlsExempt = ['/session/', '/segment/', '/sessions/'];

export const useBreadcrumbs = () => {
    const pathname = usePathname();

    const { setBreadcrumbs, setHasClickedOwnLeaderboard } = useTrackTitanStore((state) => state.breadcrumbs);
    const { breadcrumbs } = useTrackTitanHydratedStore((state) => state.breadcrumbs);

    useEffect(() => {
        if (pathname && breadcrumbs.length !== 0 && !urlsExempt.some((url) => pathname.includes(url))) {
            setBreadcrumbs([]);
            setHasClickedOwnLeaderboard(false);
        }
    }, [breadcrumbs, setBreadcrumbs, pathname]);
};
