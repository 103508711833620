import { usePathname } from 'next/navigation';
import { useGetUserDetails } from 'src/queries/user/useGetUserDetails';
import { useTrackTitanHydratedStore } from 'src/zustand/hydrated';

export const useShowSimplifiedLayout = () => {
    const { user } = useTrackTitanHydratedStore((state) => state.auth);
    const { data: userDetailsData } = useGetUserDetails(user?.uuid);
    const pathname = usePathname();

    const hasCompletedActivation = userDetailsData?.has_completed_activation ?? true;
    const onDashboard = pathname?.includes('dashboard');

    return onDashboard && !hasCompletedActivation;
};
