import { useEffect, useState } from 'react';
import { useTrackTitanHydratedStore } from 'src/zustand/hydrated';

export function useFBQTrack() {
    const [isInitialised, setIsInitialised] = useState<boolean>(false);
    const { user } = useTrackTitanHydratedStore((state) => state.auth);

    const initialise = () => {
        if (!window.fbq) {
            return;
        }
        window.fbq('init', '456879459178777', {
            em: user?.email,
            external_id: user?.uuid
        });

        setIsInitialised(true);
    };

    useEffect(() => {
        initialise();
    }, [user]);

    const track = (params: { trackEvent: TrackType }, options?: any) => {
        const { trackEvent } = params;

        if (!isInitialised) {
            initialise();
        }

        if (!window.fbq) {
            return;
        }

        window.fbq('track', trackEvent, options);
    };

    const trackCustom = (params: { trackEvent: string }, options?: any) => {
        const { trackEvent } = params;

        if (!isInitialised) {
            initialise();
        }

        if (!window.fbq) {
            return;
        }

        window.fbq('trackCustom', trackEvent, options);
    };

    return { track, trackCustom };
}

export const trackTypes = [
    'AddPaymentInfo',
    'AddToCart',
    'AddToWishlist',
    'CompleteRegistration',
    'Contact',
    'CustomizeProduct',
    'Donate',
    'FindLocation',
    'InitiateCheckout',
    'Lead',
    'Purchase',
    'Schedule',
    'Search',
    'StartTrial',
    'SubmitApplication',
    'Subscribe',
    'ViewContent',
    'PageView'
] as const;

export type TrackType = typeof trackTypes[number];

declare global {
    interface Window {
        fbq?: (command: string, event: string, options?: any) => {};
    }
}
