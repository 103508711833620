import { usePathname } from 'next/navigation';
import { useMemo } from 'react';
import { useTrackTitanHydratedStore } from 'src/zustand/hydrated';

export const useRaceEngineerActive = () => {
    const pathname = usePathname();
    const { gameId } = useTrackTitanHydratedStore((state) => state.analysis);

    const isRaceEngineerActive = useMemo(() => {
        if (!pathname) return undefined;

        if (
            gameId === 'f1_real_life' &&
            (pathname.includes('/session/') || pathname.includes('/segment/') || pathname.includes('/sessions/'))
        ) {
            return true;
        }

        return undefined;
    }, [pathname, gameId]);

    return isRaceEngineerActive;
};
