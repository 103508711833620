import axios from 'axios';
import useSWR from 'swr';

import { SessionSummaryType, UsersGamePlatform, Currencies } from 'src/types';
import { SanityCarNameAndId, SanityImage } from 'src/types/sanity';
import { useTrackTitanHydratedStore } from 'src/zustand/hydrated';

import { useCountdown } from './useCountdown';

export { useCountdown };

const fetcher = (url: string) => axios.get(url).then((res) => res.data);

export type AllSessionsData = {
    latestSessions: SessionSummaryType[];
    usersConfiguredGames: UsersGamePlatform[];
    acHotlapLink: string;
    cars: SanityCarNameAndId[];
};

export type PaymentPlans =
    | 'community'
    | 'basic-paid-monthly-plan'
    | 'premium-paid-monthly-plan'
    | 'basic-paid-annual-plan'
    | 'premium-paid-annual-plan'
    | 'ultra-paid-monthly-plan'
    | 'ultra-paid-annual-plan';

export type SetupPaymentPlans = 'setup-subscription-paid-monthly-plan' | 'setup-subscription-paid-annual-plan';

type SubscriptionPlanResponse = {
    plans: {
        planId: PaymentPlans | SetupPaymentPlans;
        pricing: {
            schedule: {
                price: {
                    currency: Currencies;
                    displayPrice: string;
                };
                period: 'MONTH' | 'YEAR';
            }[];
        };
    }[];
};

export const useSubscriptionPlans = (region?: string, country?: string, includeSetups?: boolean) => {
    const urlParams = new URLSearchParams();

    if (region) urlParams.append('region', region);
    if (country) urlParams.append('country', country);
    if (includeSetups) urlParams.append('includeSetups', includeSetups.toString());

    const urlParamsString = urlParams.size > 0 ? `?${urlParams.toString()}` : '';

    const requestUrl = `${process.env.NEXT_PUBLIC_GAIA_API}/api/v1/subscription-plan${urlParamsString}`;

    const { data, error } = useSWR<SubscriptionPlanResponse>(requestUrl, fetcher);

    return {
        data,
        isLoading: !error && !data,
        isError: error
    };
};

type LeaderboardEntry = {
    uuid: string;
    displayName: string;
    lapTime: number;
    sessionId: string;
    lapNumber: number;
    timeStamp: string;
};

export type LeaderboardResponse = {
    game: {
        image?: SanityImage;
        name: string;
    };
    track: {
        image?: SanityImage;
        name: string;
    };
    car?: {
        image?: SanityImage;
        name: string;
    };
    leaderboard: LeaderboardEntry[];
};

// TODO: remove this shit
export const useLapColors = () => {
    const { isTestMode } = useTrackTitanHydratedStore((state) => state.auth);

    return {
        comparisonColor: isTestMode ? '#00FFD1' : '#F1860D',
        referenceColor: '#2C6BB3',
        tipsColor: '#f9d62b'
    };
};
