'use client';

import { SupportedGame } from 'components/SupportedGames';
import { ReactNode } from 'react';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { PageLayout } from 'src/layouts/PageLayout';
import { IMiscImage } from 'src/queries/sanity/getMiscImages';
import './globals.css';
import { UtmData } from 'src/zustand/app';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

interface LayoutClientProps {
    children: ReactNode;
    supportedGames?: SupportedGame[];
    miscImages?: IMiscImage[];
    loadingAdvice?: string;
    cancelPlanParam?: string;
    showOnboardingModal?: string;
    payment_success?: string;
    gameSelectionGameId?: string;
    utmData: UtmData;
}

export const LayoutClient = ({
    children,
    supportedGames,
    miscImages,
    loadingAdvice,
    cancelPlanParam,
    showOnboardingModal,
    payment_success,
    gameSelectionGameId,
    utmData
}: LayoutClientProps) => (
    <QueryClientProvider client={queryClient}>
        <PageLayout
            supportedGames={supportedGames}
            miscImages={miscImages}
            loadingAdvice={loadingAdvice}
            cancelPlanParam={cancelPlanParam}
            showOnboardingModal={showOnboardingModal}
            payment_success={payment_success}
            gameSelectionGameId={gameSelectionGameId}
            utmData={utmData}
        >
            {children}
        </PageLayout>
        <Toaster />
        {process.env.NEXT_PUBLIC_SHOW_REACT_QUERY_DEV_TOOLS === 'true' && (
            <ReactQueryDevtools initialIsOpen={false} position="top-right" />
        )}
    </QueryClientProvider>
);
