import { FC, useEffect } from 'react';
import Tooltip from 'react-tooltip';
import Image from 'next/image';

import { SanityImageWithSkeleton } from 'components/SanityImageWithSkeleton';
import { useCountdown } from 'src/hooks';
import { SanityImage } from 'src/types/sanity';

type Props = {
    goNextSlide: () => void;
    showDownloadButton: boolean;
    handleDownloadClick: () => void;
    completedCountdown: boolean;
    setCompletedCountdown: (completedCountdown: boolean) => void;
    onboardingMiscImage?: SanityImage;
};

export const DataLogger: FC<Props> = ({
    goNextSlide,
    showDownloadButton,
    handleDownloadClick,
    completedCountdown,
    setCompletedCountdown,
    onboardingMiscImage
}) => {
    const { secondsRemaining } = useCountdown(30);

    const isCTADisabled = !completedCountdown && secondsRemaining > 0;

    useEffect(() => {
        if (secondsRemaining === 0) {
            setCompletedCountdown(true);
        }
    }, [secondsRemaining, setCompletedCountdown]);

    return (
        <div className="mt-8 px-24 pb-10 flex flex-col">
            <p className="mb-4">
                The data logger runs on any PC/Mac and transforms your racing line, braking and steering inputs from
                your in-game laps into a clear visualisation on Track Titan.
            </p>

            <div className="grid grid-cols-max-2 gap-8">
                <div style={{ width: 200 }}>
                    <SanityImageWithSkeleton sanityImage={onboardingMiscImage} skeletonHeight={300} />
                </div>

                <ul className="text-xl self-center flex flex-col gap-6">
                    <li className="flex items-center gap-2">
                        <span className="flex justify-center items-center border-2 rounded-full w-10 h-10">1</span>
                        {showDownloadButton ? (
                            <button
                                className="bg-newOrange rounded py-1 px-4 text-xl flex justify-center items-center gap-2 ml"
                                onClick={handleDownloadClick}
                            >
                                Download
                                <div className="flex relative" style={{ top: -2 }}>
                                    <Image
                                        src="/download.svg"
                                        alt="download"
                                        width="32"
                                        height="32"
                                        style={{
                                            maxWidth: '100%',
                                            height: 'auto'
                                        }}
                                    />
                                </div>
                            </button>
                        ) : (
                            'Download'
                        )}
                    </li>
                    <li className="flex items-center gap-2">
                        <span className="flex justify-center items-center border-2 rounded-full w-10 h-10">2</span>
                        Install
                    </li>
                    <li className="flex items-center gap-2">
                        <span className="flex justify-center items-center border-2 rounded-full w-10 h-10">3</span>
                        Login
                    </li>
                </ul>
            </div>

            <div className="mt-6 flex justify-end items-center gap-4">
                {isCTADisabled && <p className="w-3/4 text-right">Login to logger then continue here</p>}
                <button
                    className="bg-success rounded disabled:bg-grey-05 disabled:text-white text-black"
                    disabled={isCTADisabled}
                    onClick={goNextSlide}
                >
                    <div data-tip="Make sure to login to the data logger on your computer" className="py-2 px-4 w-max">
                        Set up your game
                    </div>
                </button>
                <Tooltip multiline className="text-lg" />
            </div>
        </div>
    );
};
