import Image from 'next/image';

import SideNavGroup from 'components/SideNavGroup';
import SideNavItem from 'components/SideNavItem';
import { Logout } from 'components/Icons/Logout';
import { User } from 'components/Icons/User';
import { UserType } from 'src/types';

interface IUserLinks {
    handleClickAnalytics: (route: string) => () => void;
    isLinkActive: (route?: string) => boolean;
    isCollapsed?: boolean;
    user: UserType;
    onSignOut: () => void;
}

export const UserLinks = ({ handleClickAnalytics, isLinkActive, isCollapsed = false, user, onSignOut }: IUserLinks) => {
    return (
        <SideNavGroup name="User">
            <SideNavItem
                link={`/profile/${user.uuid}`}
                onClick={handleClickAnalytics('profile')}
                isLinkActive={isLinkActive}
            >
                <User className="h-6 w-6 scale-75" />
                {!isCollapsed && 'Profile'}
            </SideNavItem>

            <SideNavItem link="/settings" onClick={handleClickAnalytics('settings')} isLinkActive={isLinkActive}>
                <Image
                    src="/settings-icon.svg"
                    alt="settings"
                    width="24"
                    height="24"
                    style={{
                        maxWidth: '100%',
                        height: 'auto'
                    }}
                />
                {!isCollapsed && 'Settings'}
            </SideNavItem>
            <SideNavItem link="/login" onClick={onSignOut} isLinkActive={isLinkActive}>
                <Logout className="h-6 w-6 scale-75 ml-0.5" />
                {!isCollapsed && 'Logout'}
            </SideNavItem>
        </SideNavGroup>
    );
};
