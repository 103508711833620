import { FC } from 'react';
import Link from 'next/link';
import Image from 'next/image';

import { Rocket } from 'components/Icons/Rocket';
import { Bug } from 'components/Icons/Bug';
import { Discord } from 'components/Icons/Discord';
import { useMediaQuery } from 'react-responsive';
import { mediaQuery } from 'src/tokens';
import { usePathname } from 'next/navigation';
import { useTrackTitanHydratedStore } from 'src/zustand/hydrated';

export const Footer: FC = () => {
    const { user } = useTrackTitanHydratedStore((state) => state.auth);
    const isDesktopOrLarger = useMediaQuery({ query: mediaQuery.largeLaptop });

    const pathname = usePathname();

    return (
        <footer className="flex justify-center bg-newFooterBackground w-full">
            <div className="w-full flex justify-center lg:justify-between py-4 md:py-6 md:px-4">
                <div className="hidden lg:flex items-center">
                    <Link href="/" className="flex">
                        <Image
                            src="/wordmark.png"
                            alt="track titan"
                            width={isDesktopOrLarger ? '240' : '160'}
                            height={isDesktopOrLarger ? '24' : '16'}
                            style={{
                                maxWidth: '100%',
                                height: 'auto'
                            }}
                        />
                    </Link>
                </div>

                <div className="flex flex-col md:flex-row gap-3 md:gap-4 mx-4">
                    <div className="flex items-center gap-3 md:gap-4">
                        <Link
                            href="/pricing"
                            className="flex hover:underline items-center gap-2 border-r pr-3 md:pr-6 w-40 md:w-auto"
                        >
                            <Rocket className="w-6 h-6" />
                            Memberships
                        </Link>
                        <a
                            className="flex hover:underline items-center gap-2 md:border-r pr-3 md:pr-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://tracktitan.typeform.com/to/g5y1fahS#user=${
                                user?.uuid || ''
                            }&source_page=${pathname}`}
                        >
                            <Bug className="w-6 h-6" />
                            Report a bug
                        </a>
                    </div>
                    <div className="flex items-center justify-center gap-3 md:gap-4 mt-2 md:mt-0">
                        <a
                            className="flex hover:underline items-center gap-2 md:border-r md:pr-6"
                            href="https://www.tracktitan.io/affiliates"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Image
                                src="/affiliate.svg"
                                alt="Affiliates"
                                width="24"
                                height="24"
                                style={{
                                    maxWidth: '100%',
                                    height: 'auto'
                                }}
                            />
                            Affiliates
                        </a>
                    </div>
                    <div className="flex items-center gap-3 md:gap-4 mt-2 md:mt-0">
                        <a
                            className="flex hover:underline items-center gap-2 border-r pr-3 md:pr-6 w-40 md:w-auto"
                            href="https://app.privasee.io/privacy-portal/64356fd7fe320800131b3ccd"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Image
                                src="/privacy.png"
                                alt="Privacy Policy"
                                width="24"
                                height="24"
                                style={{
                                    maxWidth: '100%',
                                    height: 'auto'
                                }}
                            />
                            Privacy Policy
                        </a>

                        <a
                            className="md:w-auto flex  hover:underline items-center gap-2 justify-center flex-1 pr-3 md:pr-0"
                            href="https://discord.gg/Dzh77esRjU"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Discord className="w-6 h-6" />
                            Join our Discord
                        </a>
                    </div>

                    <div className="flex justify-around items-center gap-1">
                        <a
                            className="flex "
                            href="https://www.tiktok.com/@tracktitan"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Image
                                src="/tiktok.svg"
                                alt="tiktok"
                                width="24"
                                height="24"
                                style={{
                                    maxWidth: '100%',
                                    height: 'auto'
                                }}
                            />
                        </a>

                        <a
                            className="flex "
                            href="https://www.instagram.com/tracktitan.io/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Image
                                src="/instagram.svg"
                                alt="instagram"
                                width="24"
                                height="24"
                                style={{
                                    maxWidth: '100%',
                                    height: 'auto'
                                }}
                            />
                        </a>

                        <a
                            className="flex "
                            href="https://www.facebook.com/tracktitan.io"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Image
                                src="/facebook.svg"
                                alt="facebook"
                                width="24"
                                height="24"
                                style={{
                                    maxWidth: '100%',
                                    height: 'auto'
                                }}
                            />
                        </a>

                        <a
                            className="flex "
                            href="https://twitter.com/tracktitanio"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Image
                                src="/x.svg"
                                alt="twitter"
                                width="24"
                                height="24"
                                style={{
                                    maxWidth: '100%',
                                    height: 'auto'
                                }}
                            />
                        </a>

                        <a
                            className="flex "
                            href="https://www.youtube.com/channel/UCb7kApZWrBw1mPcDPcLjXQA"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Image
                                src="/youtube.svg"
                                alt="youtube"
                                width="24"
                                height="24"
                                style={{
                                    maxWidth: '100%',
                                    height: 'auto'
                                }}
                            />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};
